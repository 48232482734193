import React, {useState} from "react";
import SEO from "../../components/seo"
import classnames from 'classnames';
import editorImg from '../../images/guide-editor.png';
import Guide from '../../components/Guide';

const Overview = () => {
  const [area, setArea] = useState('targets');

  return (
    <div className="overview">
      <p>The editor consists of four main panels:</p>
      <div className="options">
        <div className={classnames({selected: area==='targets'}, "item")} onClick={() => {setArea("targets")}}>Targets Panel</div>
        <div className={classnames({selected: area==='scene'}, "item")} onClick={() => {setArea("scene")}}>Scene Panel</div>
        <div className={classnames({selected: area==='properties'}, "item")} onClick={() => {setArea("properties")}}>Properties Panel</div>
        <div className={classnames({selected: area==='menu'}, "item")} onClick={() => {setArea("menu")}}>Menu</div>
      </div>
      <div className="viewport">
        <div className="inner">
	  <img alt="pictarize-editor" src={editorImg} />

	  {area === 'targets' && <div className="panel targets-panel"></div>}
	  {area === 'scene' && <div className="panel scene-panel"></div>}
	  {area === 'properties' && <div className="panel properties-panel"></div>}
	  {area === 'menu' && <div className="panel menu-panel"></div>}

    	  {area === 'targets' && (
	    <div className="explanation">
	      <h2>Targets Panel</h2>
	      <li>
	    	Target images are added here. You can have multiple targets, and each of them will have a unique AR effect scenes.
	      </li>
	      <li>
		Then for each targets, you can add contents to it.
	      </li>
	      <li>
	    	At the bottom is the estimated project size. Keeping it low to ensure fast loading time.
	      </li>
	    </div>
	  )}

    	  {area === 'scene' && (
	    <div className="explanation">
	      <h2>Scene Panel</h2>
	      <li>
	    	At the center is the scene panel. This is where you visualize and manipulate the AR contents. 
	      </li>
	      <li>
	        Navigate/Control with your mouse
	        <ol>
		  <li>wheel to zoom.</li>
		  <li>hold left button and drag to rotate</li>
		  <li>hold right button and drag to move</li>
	    	  <li>click to select content, then move/rotate/scale</li>
		</ol>
	      </li>
	      <li>
	        A simulation button is on the top-right corner, where you can quickly examine the expected outcome.
	      </li>
	    </div>
	  )}

    	  {area === 'properties' && (
	    <div className="explanation">
	      <h2>Properties Panel</h2>
	      <li>
	    	When you select an image target or any contents, this Properties Panel will show up.
	      </li>
	      <li>
	    	You can modify the properties of the selected items, like position and scale.
	      </li>
	      <li>
	        Each content type has slightly different settings. For example, a video content allows you to specify whether you want it to auto start.
	      </li>
	    </div>
	  )}

    	  {area === 'menu' && (
	    <div className="explanation">
	      <h2>Menu</h2>
	      <li>
	        Menu bar is on the top, where you can save and publish project.
	      </li>
	    </div>
	  )}
	</div>
      </div>
    </div>
  )
}

const Page = () => {
  return (
    <Guide tab="editor">
      <SEO title="Pictarize | Editor Manual | Editor Guide"/>

      <div className="editor-guide">
	<h1>Editor Guide</h1>
	<Overview />

	{/*
	<h1>Target Images</h1>
	<p>Target images are the images that you want the AR effects appeared upon. Theoretically speaking, you can upload any pictures of your choices. But there are certain properties that make a image better target for detection and tracking.</p>
	*/}

	<h1>Content Types</h1>
	<p>Pictarize supports a rich variety of content types. It gives you enough building blocks for any AR effects.</p>

	<h2>3D Models</h2>
	<p>Our platform is compatible with most 3D models marketplaces, like <a href="https://sketchfab.com/" target="_blank" rel="noreferrer">SketchFab</a> and <a href="https://www.turbosquid.com/" target="_blank" rel="noreferrer">Turbosquid</a>.</p>
	<p>You can upload any .gltf or .glb formatted 3D models to the platform and use them directly. gltf and glb formats are optimized for web applications, and they are also the default downloadable formats from SketchFab.</p>
	<p>If you are creating your own models with software like Blender or Maya, you can also easily export them to this format.</p>

	<h2>Videos and Audios</h2>
	<p>Our platform support uploaded videos and audios. To ensure cross browser compatibility, we currently supports .mp3 and .mp4 formats.</p>

	<p>You can easily control the playback, like loop and autostart in the Properties Panel.</p>

	<p>Transparent videos are also supported in terms of green screen video (i.e. Chroma video). In the properties panel, there is an option to remove green screen.</p>
	<p>
	  <strong>Noted that uploaded videos are mostly used for effects.</strong> The whole video needed to be downloaded by users before your AR effects can start, so we do not recommend uploading a long video because it will greatly increase the loading time of the web application. You can, however, use embedded youtube/vimeo videos if you want to show a long video.</p>

	<h2>Embedded Videos</h2>
	<p>
	  Our platform also support embedding youtube and vimeo videos. The biggest difference is that users do not need to download the whole video before starting the effects. They are being streamed while users are watching it.
	</p>
	<p>
	  If you have a real videos that you do expect users to watch, you should always go for this option.
	</p>
      
	<h2>Images</h2>
	<p>Our platform supports all common image formats, like jpeg and png.</p>

	<h2>Texts</h2>
	<p>You can directly create texts inside the editor, and customize the color, size, alignment, etc.</p>

	<h1>Custom Script</h1>
	<p>While you can simply do drag-n-drop to build up a basic AR effect, sometimes you might still want to create highly interactive applications, or even games.</p> 
	<p>For example, if you are building an interative AR business card, you might want to add buttons. When users tap on the buttons, you will show certain information or play a demo video. Or if you are showcasing a product, you might want to create cool transitional effeccts, like fade in / fade out.</p>

	<p>Pictarize comes with a powerful scripting engine, where you can write custom javascript code to control the contents easily. Check out the <a href="/guide/script">Scripting Guide</a> for further information.</p>

	<h1>Publish Project</h1>
	<p>Pictarize is an all-in-one solution for building and publishing web AR applications. After building your AR effects, you can publish and manage the releases right inside the studio. Check out the <a href="/guide/publish">Publish Guide</a> for further information.</p>
      </div>
    </Guide>
  )
}

export default Page;
